import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';
import Carousel from '../../../Carousel';
import Image from '../../../Image';
import ModalBoxInline from '../../../../components/ModalBoxInline';

import styles from './styles.module.css';

class ProductsLayout extends React.Component {

  constructor(props) {

    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      full,
      align,
      showModal: false,
      modalIndex: 0,
      columns,
    };

    this.createImage = this.createImage.bind(this);
    this.createContent = this.createContent.bind(this);
    this.createModalDesign2 = this.createModalDesign2.bind(this);
    this.createModalDesign3 = this.createModalDesign3.bind(this);
    this.createCarousel = this.createCarousel.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

  }

  handleCardClick(index) {

    this.setState({
      showModal: true,
      modalIndex: index,
    });

  }

  handleCloseModal() {

    this.setState({
      showModal: false,
    });

  }

  createModalDesign2(index, item, images, imageCount) {

    const align = `align${item.align}`;
    const subtitle = `Title3${this.props.themeData.typography.heading.fontSize}`;
    const body = `Body${this.props.themeData.typography.default.fontSize}`;
    const { themeData } = this.props;

    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      fontWeight: themeData.typography.heading.weight,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
    };

    const themeDefaultStyle = {
      fontFamily: themeData.typography.default.name,
      weight: themeData.typography.default.weight,
      lineHeight: themeData.typography.default.lineHeight,
      letterSpacing: themeData.typography.default.letterSpacing,
    };

    const color3 = {
      color: themeData.colors[3],
    };

    let image;
    const texts = [];
    let first = false;
    let imageAdded = false;
    item.data.forEach((elem, i) => {

      if (imageCount === 1 && elem.active && elem.type === 'IMAGES/IMAGE') {

        image = (
          <div className={styles.modalLeftColumn}>
            { this.createImage(elem, index, i, 'productsImageModalWrapper', 'productsImageModal', item.align, false)}
          </div>
        );

      } else if (imageCount > 1 && !imageAdded && elem.active && elem.type === 'IMAGES/IMAGE') {

        image = (
          <div className={styles.modalLeftColumnDesign2}>
            {this.createCarousel(images, 'productsCarouselWrapper', 'carouselWrapper')}
          </div>
        );

        imageAdded = true;

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let styleName;
        let eStyle;
        let data;
        if (elem.type === 'HEADINGS/HEADING-THREE') {

          first = true;
          styleName = `${styles.productsComponentHeading} ${styles[subtitle]} ${styles.exceptionWeight} ${styles[align]}`;
          eStyle = { ...themeHeadingStyle, ...color3 };
          data = elem.text ? `<h3 style={eStyle}>${elem.text}</h3>` : elem.text;

        } else {

          styleName = `${styles.productsComponentParagraph} ${styles[body]} ${styles[align]}`;
          eStyle = themeDefaultStyle;
          data = elem.text ? `<span style={eStyle}>${elem.text}</span>` : elem.text;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        if (i === 3) texts.splice(texts.length === 1 && !first ? 0 : 1, 0, text);
        else texts.push(text);

      }

    });

    const data = (

      <div className={styles.productsModalWrapper}>
        { image }
        <div className={styles.modalRightColumn} style={!image ? { width: '100%' } : undefined}>
          <div className={styles.modalCloseButton}>
            <i
              className={`entypo icon-cancel ${styles.modalCloseIcon}`}
              onClick={this.handleCloseModal}
            />
          </div>
          <div className={styles.modalTextWrapper}>
            { texts }
          </div>
        </div>
      </div>

    );

    if (this.state.modalIndex === index) {

      return (
        <ModalBoxInline
          isOpen={this.state.showModal}
          id="productsModal"
          onCloseClick={this.handleCloseModal}
          data={data}
          noBox
          noHeaderOnMobile
        />
      );

    }

    return null;

  }

  createModalDesign3(images, index, item, imageCount) {

    const align = `align${item.align}`;

    const subtitle = `Title3${this.props.themeData.typography.heading.fontSize}`;
    const body = `Body${this.props.themeData.typography.default.fontSize}`;

    const { themeData } = this.props;

    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      fontWeight: themeData.typography.heading.weight,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
    };

    const themeDefaultStyle = {
      fontFamily: themeData.typography.default.name,
      weight: themeData.typography.default.weight,
      lineHeight: themeData.typography.default.lineHeight,
      letterSpacing: themeData.typography.default.letterSpacing,
    };

    const color3 = {
      color: themeData.colors[3],
    };

    let image;
    const texts = [];
    let first = false;
    let imageAdded = false;

    item.data.forEach((elem, i) => {

      if (imageCount === 1 && elem.active && elem.type === 'IMAGES/IMAGE') {

        image = (
          <div className={styles.productsCarouselImageWrapperDesign3}>
            { this.createImage(elem, index, i, 'productsImageModalWrapper', 'productsImageModalDesign3', item.align, false)}
          </div>
        );

      } else if (imageCount > 1 && !imageAdded && elem.active && elem.type === 'IMAGES/IMAGE') {

        image = this.createCarousel(images, 'productsCarouselWrapperDesign3', 'modalCarouselWrapper');
        imageAdded = true;

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        let styleName;
        let eStyle;
        let data;
        if (elem.type === 'HEADINGS/HEADING-THREE') {

          first = true;
          styleName = `${styles.productsComponentHeading} ${styles[subtitle]} ${styles.exceptionWeight} ${styles[align]}`;
          eStyle = { ...themeHeadingStyle, ...color3 };
          data = elem.text ? `<h3 style={eStyle}>${elem.text}</h3>` : elem.text;

        } else {

          styleName = `${styles.productsComponentParagraph} ${styles[body]} ${styles[align]}`;
          eStyle = themeDefaultStyle;
          data = elem.text ? `<span style={eStyle}>${elem.text}</span>` : elem.text;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        if (i === 3) texts.splice(texts.length === 1 && !first ? 0 : 1, 0, text);
        else texts.push(text);

      }

    });

    const data = (
      <div className={styles.productsModalWrapperDesign3}>
        <div className={styles.modalCloseButtonDesign3}>
          <i
            className={`entypo icon-cancel ${styles.modalCloseIcon}`}
            onClick={this.handleCloseModal}
          />
        </div>
        <div className={styles.modalTextWrapperDesign3}>
          { texts }
        </div>
        { image }
      </div>
    );

    if (this.state.modalIndex === index) {

      return (
        <ModalBoxInline
          isOpen={this.state.showModal}
          id="productsModal"
          onCloseClick={this.handleCloseModal}
          data={data}
          noBox
          noHeaderOnMobile
        />
      );

    }

    return null;

  }

  /* eslint-disable class-methods-use-this */
  createCarousel(images, cardWrapper, carouselWrapper) {

    const arrows = true;
    const cards = [];

    images.forEach((item, i) => {

      if (item.type === 'IMAGES/IMAGE' && item.active) {

        const card = (
          <div key={`productsImage_card_${i}`} className={styles[cardWrapper]}>
            <div className={styles.productsCarouselImageWrapper}>
              <Image
                id={item.content.id}
                alt={item.content.alt || ''}
                imageClass="productsCarouselImg"
                className=""
                images={this.props.images}
              />
            </div>
          </div>
        );

        cards.push(card);

      }

      return null;

    });

    const carousel = (

      <div className={styles[carouselWrapper]}>
        <Carousel
          showArrows={arrows}
          showIndicators
          containerClass="carouselContainerProducts"
          autoPlay={false}
          infiniteLoop={false}
        >
          { cards }
        </Carousel>
      </div>

    );

    return carousel;

  }

  /* eslint-enable class-methods-use-this */

  createImage(item, cIndex, eIndex, wrapperStyle, elementStyle, align) {

    return (<SectionMedia
      id={item.content.id}
      mediaType={item.content.icon ? 'ICON' : 'IMAGE'}
      wrapperStyle={wrapperStyle}
      elementStyle={elementStyle}
      align={align}
      src={item.content.CDNLink ? item.content.CDNLink : `${item.content.src}`}
      alt={item.content.alt}
      data={item.content}
      images={this.props.images}
      pagePathList={this.props.pagePathList}
      articlePathList={this.props.articlePathList}
      filePathList={this.props.filePathList}
    />);

  }

  createContent(item, index, cols) {

    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === ''
        && item.styles.backgroundColor.gradient.from === ''
      ) backgroundColor = { solid: '#ffffff' };

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
      );

    }

    const align = `align${item.align}`;
    const subtitle = `Title3${this.props.themeData.typography.heading.fontSize}`;
    const body = `Body${this.props.themeData.typography.default.fontSize}`;
    let design = this.props.layout === 'left' ? 'Left' : this.props.layout === 'design2' ? 'Design2' : '';
    design = this.props.layout === 'design3' ? 'Design3' : design;
    const { themeData } = this.props;

    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      fontWeight: themeData.typography.heading.weight,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
    };

    const themeDefaultStyle = {
      fontFamily: themeData.typography.default.name,
      weight: themeData.typography.default.weight,
      lineHeight: themeData.typography.default.lineHeight,
      letterSpacing: themeData.typography.default.letterSpacing,
    };

    const color3 = {
      color: themeData.colors[3],
    };

    const texts = [];
    let image;
    let imageCount = 0;
    const images = [];
    let imageAdded = false;

    item.data.forEach((element) => {

      if (element.type === 'IMAGES/IMAGE') {

        imageCount += 1;
        images.push(element);

      }

    });

    item.data.forEach((elem, i) => {

      if (imageCount === 1 && elem.active && elem.type === 'IMAGES/IMAGE') {

        image = (
          <div className={`${styles[`productsImageWrapper${design}`]}${this.props.layout === 'left' || this.props.layout === 'design3' ? ` ${styles.productsCard}` : ''}`}>
            { this.createImage(elem, index, i, 'productsImageWrapper', `productsImageElement${this.props.layout === 'design2' ? 'Design2' : this.props.layout === 'design3' ? 'Design3' : ''}`, item.align)}
          </div>
        );

      } else if (imageCount > 1 && !imageAdded && elem.active && elem.type === 'IMAGES/IMAGE') {

        if (this.props.layout !== 'design2') {

          image = (
            <div
              className={`${styles[`productsImageWrapper${design}`]}${this.props.layout === 'left' || this.props.layout === 'design3' ? ` ${styles.productsCard}` : ''}`}>
              {this.createImage(elem, 0, 0, 'productsImageWrapper', `productsImageElement${this.props.layout === 'design2' ? 'Design2' : this.props.layout === 'design3' ? 'Design3' : ''}`, item.align, true)}
            </div>
          );

          imageAdded = true;

        } else {

          image = (
            <div
              className={`${styles[`productsImageWrapper${design}`]}${this.props.layout === 'left' || this.props.layout === 'design3' ? ` ${styles.productsCard}` : ''}`}>
              {this.createCarousel(images, 'productsCarouselWrapper', 'carouselWrapper')}
            </div>
          );

          imageAdded = true;

        }

      } else if (elem.active && (elem.type === 'HEADINGS/HEADING-THREE' || elem.type === 'PARAGRAPH/PARAGRAPH')) {

        if (this.props.layout === 'design2') {

          let styleName;
          let eStyle;
          let data;

          if (elem.type === 'HEADINGS/HEADING-THREE') {

            styleName = `${styles.productsComponentHeading} ${styles[subtitle]} ${styles.exceptionWeight} ${styles[align]}`;
            eStyle = { ...themeHeadingStyle, ...color3 }
            data = `<h3 style={eStyle}>${elem.text}</h3>`;


          } else {

            styleName = `${styles.productsComponentParagraph} ${styles[body]} ${styles[align]}`;
            eStyle = themeDefaultStyle;
            data = `<span style={eStyle}>${elem.text}</span>`;

          }

          const text = (
            <div
              key={`${this.props.section._id}_elem_${index}${i}`}
              className={styleName}
            >
              {HTMLParser(data)}
            </div>
          );

          texts.push(text);

        } else if (this.props.layout === 'design3') {

          if (i === 1 || i === 2) {

            let data;
            let styleName;
            let eStyle;

            if (elem.type === 'HEADINGS/HEADING-THREE') {

              styleName = `${styles.productsComponentHeading} ${styles[subtitle]} ${styles.exceptionWeight} ${styles[align]}`;
              eStyle = { ...themeHeadingStyle, ...color3 };
              data = `<h3 style={eStyle}>${elem.text}</h3>`;

            } else {

              styleName = `${styles.productsComponentParagraph} ${styles[body]} ${styles[align]}`;
              eStyle = themeDefaultStyle;
              data = `<span style={eStyle}>${elem.text}</span>`;

            }

            const text = (
              <div
                key={`${this.props.section._id}_elem_${index}${i}_section`}
                className={styleName}
              >
                {HTMLParser(data)}
              </div>
            );

            texts.push(text);

          }

        } else if (this.props.layout === 'left') {

          if (i === 1 || i === 2) {

            let data;
            let styleName;
            let eStyle;

            if (elem.type === 'HEADINGS/HEADING-THREE') {

              styleName = `${styles.productsComponentHeading} ${styles[subtitle]} ${styles.exceptionWeight} ${styles[align]}`;
              eStyle = { ...themeHeadingStyle, ...color3 };
              data = `<h3 style={eStyle}>${elem.text}</h3>`;

            } else {

              styleName = `${styles.productsComponentParagraph} ${styles[body]} ${styles[align]}`;
              eStyle = themeDefaultStyle;
              data = `<span style={eStyle}>${elem.text}</span>`;

            }

            const text = (
              <div
                key={`${this.props.section._id}_elem_${index}${i}_section`}
                className={styleName}
              >
                { HTMLParser(data) }
              </div>
            );

            texts.push(text);

          }

        }

      }

    });

    if (this.props.layout === 'design2') {

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          <div
            data-layout="Products"
            className={`${styles[`productsComponentsWrapper${design}`]}`}
            style={style}
          >
            { image }
            <div className={styles.productsTextBlockDesign2Wrapper}>
              <div className={styles.productsTextBlockDesign2}>
                { texts }
              </div>
            </div>
          </div>
        </div>
      );

    } else if (this.props.layout === 'design3') {

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          { this.createModalDesign3(images, index, item, imageCount) }
          <div
            data-layout="Products"
            className={`${styles[`productsComponentsWrapper${design}`]} ${styles.productsCardWrapper}`}
            style={style}
            onClick={() => this.handleCardClick(index)}
          >
            { image }
            <div className={`${styles.productsTextBlockDesign3Wrapper}`}>
              <div className={`${styles.productsTextBlockDesign3Wrapper}`}>
                { texts }
              </div>
            </div>
          </div>
        </div>
      );

    } else if (this.props.layout === 'left') {

      return (
        <div
          key={`${this.props.section._id}_layout_${index}`}
          className={`${cols} ${styles.colEqualHeights}`}
        >
          { this.createModalDesign2(index, item, images, imageCount) }
          <div
            id={`${this.props.section._id}_Component_${index}_section`}
            data-layout="Products"
            tabIndex={0}
            className={`${styles.productsComponentsWrapperLeft} ${styles.productsCardWrapper}`}
            style={style}
            onClick={() => this.handleCardClick(index)}
          >
            { image }
            <div className={styles.productsTextWrapper}>
              { texts }
            </div>
          </div>
        </div>
      );

    }

    return null;

  }


  render() {

    let cols;
    if (this.state.columns === 1) cols = 'col-12';
    else cols = `col-12 col-sm-6 col-md-${this.state.columns ? (12 / this.state.columns) : 4}`;

    const activeComponents = [];
    const top = [];
    this.props.section.data.map((item, index) => {

      if (item.active && item.type === 'COMPONENT') {

        const content = this.createContent(item, index, cols);
        activeComponents.push(content);

      } else if (
        item.active
        && (item.type === 'HEADINGS/HEADING-TWO' || item.type === 'PARAGRAPH/PARAGRAPH')
      ) {

        let styleName;
        let data;
        if (item.type === 'HEADINGS/HEADING-TWO') {

          styleName = `col-12 ${styles.productsMainHeader}`;
          data = `<h2>${item.text}</h2>`;


        } else {

          styleName = `col-12 ${styles.productsMainSubtitle}`;
          data = `<span>${item.text}</span>`;

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}`}
            className={styleName}
          >
            { HTMLParser(data) }
          </div>
        );

        top.push(text);

      }

      return null;

    });

    const alignStyle = `align${this.state.align}`;
    let open;
    if (this.state.showModal) open = { zIndex: 999 };

    return (
      <div className={`container ${styles[`productsContainer${this.state.full}`]}`} style={open}>
        <div className={`row ${styles[alignStyle]}`}>
          { top }
          { activeComponents }
        </div>
      </div>
    );

  }

}

ProductsLayout.propTypes = {
  page: PropTypes.string,
  section: PropTypes.shape({}),
  layout: PropTypes.string,
};

export default ProductsLayout;
