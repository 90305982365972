import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const Second = ({
  id,
  compContent,
  index,
  align,
  bg,
  wrap,
}) => {

  let floaty;
  let img = false;
  let box = false;
  for (let i = 0; i < compContent.length; i += 1) {

    if (compContent[i].props.id && compContent[i].props.id.includes('comparisonFloatyBox')) {

      floaty = compContent[i];
      compContent.splice(i, 1);
      box = true;

      let idx = 0;

      if (
        compContent[0]
        && compContent[0].props.id
        && compContent[0].props.id.includes('comparisonImageContainer')
      ) {

        idx = 1;

      }

      compContent.splice(idx, 0, floaty);

    }

    if (
      compContent[0]
      && compContent[0].props.id
      && compContent[0].props.id.includes('comparisonImageContainer')
    ) {

      img = true;

    }

  }

  let wrapIndex;
  let topIndex;
  if (img === false && box === false) {

    wrapIndex = 0;

  } else if ((img === false || box === false) && !(img === false && box === false)) {

    wrapIndex = 1;
    topIndex = 0;

  } else if (img === true && box === true) {

    wrapIndex = 2;
    topIndex = 1;

  }
  let both;
  if (wrap) {

    both = compContent[wrapIndex + 1] && compContent[wrapIndex + 1].props.className && compContent[wrapIndex + 1].props.className.includes('comparisonComponentText');

  }

  const modified = [];
  for (let i = 0; i < compContent.length; i += 1) {

    if ((img === true || box === true) && i <= topIndex) {

      if (i === topIndex) {

        modified.push(null);

      }

    } else if (wrap && (both ? i <= wrapIndex + 1 : i <= wrapIndex)) {

      if (i === wrapIndex) {

        modified.push(null);

      }

    } else {

      modified.push(compContent[i]);

    }

  }

  if ((img === false || box === false) && !(img === false && box === false)) {

    // eslint-disable-next-line
    modified[0] = compContent[0];

  } else if (img === true && box === true) {

    modified[0] = (
      <div key={`${id}_second_top`}>
        {compContent[0]}
        <div className={styles.positioner}>
          {compContent[1]}
        </div>
      </div>
    );

  }

  if (wrap) {

    modified[topIndex !== undefined ? 1 : 0] = (
      <div key={`${id}_wrapped`} className={styles.wrapper}>
        {compContent[wrapIndex]}
        {both ? compContent[wrapIndex + 1] : null}
      </div>
    );

  }

  const contentStyle = styles.comparisonComponentContentEqual;
  const componentStyle = `${styles.comparisonComponentEqual} ${styles[`align${align}`]}`;

  return (
    <div
      key={`${id}_layout_${index}`}
      className={`col-xs-12 col-sm-8 col-md-4 ${componentStyle}`}
    >
      <div
        className={contentStyle}
        style={bg !== undefined ? bg : {}}
      >
        { modified }
      </div>
    </div>
  );

};

Second.propTypes = {
  id: PropTypes.string,
  compContent: PropTypes.arrayOf(PropTypes.element),
  index: PropTypes.number,
  align: PropTypes.string,
  bg: PropTypes.shape({}),
  wrap: PropTypes.bool,
};

export default Second;
