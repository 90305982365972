import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import styles from './styles.module.css';

class EmbedLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const dataIndex = props.section.data.findIndex(elem => elem.type === 'DATA');
    const align = props.section.styles.align !== undefined ? props.section.styles.align : 'Left';

    this.state = {
      section: props.section,
      code: props.section.data[dataIndex].text,
      align,
      height: undefined,
    };

    this.embed = React.createRef();
    this.observer = false;
    this.handleLoad = this.handleLoad.bind(this);

  }

  componentDidMount() {

    const embed = this.embed.current;
    if (embed && typeof ResizeObserver === 'undefined') {

      embed.contentWindow.addEventListener('resize', this.handleLoad);

    }

  }

  componentWillUnmount() {

    const embed = this.embed.current;
    if (embed && typeof ResizeObserver === 'undefined') embed.contentWindow.removeEventListener('resize', this.handleLoad);

  }

  handleLoad(height) {

    const embed = this.embed.current;
    if (embed && this.observer === false && typeof ResizeObserver !== 'undefined') {

      const [html] = embed.contentWindow.document.getElementsByTagName('html');

      if (html) {

        const observer = new ResizeObserver(entries => {

          entries.forEach(entry => {

            this.handleLoad(Math.ceil(entry.contentRect.height));

          });

        });

        observer.observe(html);
        this.observer = true;

      }

    }

    if (
      typeof window !== 'undefined'
      && (
        height === undefined
        || (this.state.height !== undefined && height !== this.state.height)
      )
    ) {

      if (embed) {

        embed.contentWindow.requestAnimationFrame(() => {

          setTimeout(() => {

            let body;
            let html;
            let bodyScroll;
            let bodyOffset;
            let htmlOffset;

            if (height === undefined) {

              ({ body } = embed.contentWindow.document);
              [html] = embed.contentWindow.document.getElementsByTagName('html');

              bodyScroll = body.scrollHeight ? body.scrollHeight : 0;
              bodyOffset = body.offsetHeight ? body.offsetHeight : 0;
              htmlOffset = html && html.offsetHeight ? html.offsetHeight : 0;

            }

            if (
              height === undefined
              && this.observer === false
              && (bodyScroll === 0 || bodyOffset === 0 || htmlOffset === 0)
            ) {

              setTimeout(() => {

                this.handleLoad();

              }, 1000);

            } else {

              let h;
              if (height === undefined) {

                h = Math.ceil(Math.max(
                  bodyScroll,
                  bodyOffset,
                  htmlOffset,
                ));

              }

              this.setState({
                height: height || h,
              });
              embed.style.height = `${height || h}px`;

            }

          }, 90);

        });

      }

    }

  }

  render() {

    const alignStyle = `align${this.state.align}`;
    const columns = 'col-12';
    const columnStyle = 'embedInnerWrapperDesign1';
    const container = 'container';

    return (
      <div className={container}>
        <div className={`row ${styles[alignStyle]}`}>
          <div className={`${columns} ${styles[columnStyle]}`}>
            {
              this.state.section.data.map((elem, i) => {

                let result;
                if (elem.active && elem.type === 'HEADINGS/HEADING-TWO') {

                  result = (
                    <div
                      key={`${this.state.section._id}_data_${i}`}
                      className={styles.titleMargin}
                    >
                      { HTMLParser(`<h2>${elem.text}</h2>`) }
                    </div>
                  );

                } else if (elem.active && elem.type === 'HEADINGS/HEADING-FOUR') {

                  result = (
                    <div
                      key={`${this.state.section._id}_data_${i}`}
                      className={styles.subtitleMargin}
                    >
                      { HTMLParser(`<h4>${elem.text}</h4>`) }
                    </div>
                  );

                } else if (elem.active && elem.type === 'PARAGRAPH/PARAGRAPH') {

                  result = (
                    <div
                      key={`${this.props.section._id}_data_${i}`}
                      className={styles.paragraphMargin}
                    >
                      { HTMLParser(`<span>${elem.text}</span>`) }
                    </div>
                  );

                }

                return result;

              })
            }
            <iframe
              className={`${styles.embed} col-12`}
              srcDoc={`<div style="text-align: center;">${this.state.code}</div>`}
              onLoad={() => this.handleLoad()}
              ref={this.embed}
            />
          </div>
        </div>
      </div>
    );

  }

}

EmbedLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
    })),
  }),
};
export default EmbedLayout;
